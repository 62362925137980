export default [
  {
    header: 'IP Address Manager',
    icon: 'ActivityIcon',
    route: null,
    children: [
      { title: 'Subnet Manager', route: 'dashboard-ipam-subnet' },
      { title: 'Provider', route: 'dashboard-ipam-provider' },
      { title: 'Server', route: 'dashboard-ipam-server' },
    ],
  },
]
