export default [
  {
    header: 'Billing Manager',
    icon: 'CompassIcon',
    route: null,
    children: [
      { title: 'Client', route: 'dashboard-billing-client' },
    ],
  },
]
